<template>
  <div>
    <div class="container-fluid">
      <div class="p-5 text-center" v-if="loading">
        <b-spinner /><br />
        aguarde...
      </div>
     

      <EmptyState v-if="consultas.length == 0">
        <h3 class="text-muted">
          Nenhuma consulta encontrado!
        </h3>
        
      </EmptyState>

      <div v-if="!loading" class="listaItens">
        <paciente-item-card
          v-for="(consulta, index) in consultas"
          @click.native="editar(consulta)"
          :key="`consulta_${consulta.id}_i_${index}`"
          :objeto="consulta"
          :tipo="`Consulta`"
        />
      </div>
      
    </div>
  </div>
</template>

<script>
import PacienteItemCard from "../PacienteItemCard.vue";
// import moment from "moment";
import VendaLib from "../../../../libs/VendaLib";
import EmptyState from "../../../common/EmptyState.vue";
export default {
  name:"PacienteConsultas",
  components: { PacienteItemCard, EmptyState },
  props: {
    paciente: Object,

  },

  mounted() {
    this.carregaConsultas();
  },
  data() {
    return {
      loading: false,
      consultas: [],

    };
  },
  watch: {},
  computed: {},
  methods: {

    async carregaConsultas() {
      try {
      //
      this.consultas = await VendaLib?.buscarVendas({'venda_items.tipo_venda': 'Consulta', 'vendas.paciente_id': this.paciente?.id})
      } catch (error) {
        console.log(error);
         this.showToast("error", "Erro ao carregar pacientes!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
